/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule, Http } from '@angular/http';

//
import {TestComponent,DebugSize} from './TestComponent';
import { MnJsonPipe } from './MnJsonPipe';
import { MnBrandService } from './MnBrandService';
import { MnBackend } from './MnBackend';
import { MnBrandView } from './MnBrandView';
import { MnLazy, MnDynamicDirective } from './MnLazy';
import { MnImage } from './MnImage';
import { MnRow,MnCol,MnWindowSize } from './MnLayout';
import { MnSplitter } from './MnSplitter';
import { MnTextService, MnText, MnTextPipe } from "./MnText";

let MnBackendServiceFactory = (http: Http, brand:MnBrandService) => {
    return new MnBackend(http,brand);
};

@NgModule({
    imports: [
        CommonModule,
        HttpModule
    ],
    exports: [
        MnBrandView, MnJsonPipe, MnTextPipe,
        MnLazy, MnImage, MnText,
        MnRow,MnCol, TestComponent,MnSplitter,MnDynamicDirective
    ],
    declarations: [
        MnBrandView, MnJsonPipe, MnLazy, MnImage, MnText, MnTextPipe,
        MnRow,MnCol, TestComponent,DebugSize,MnSplitter,MnDynamicDirective
    ],
    entryComponents: [
        MnBrandView, MnLazy, TestComponent, DebugSize
    ],
    providers: [MnBrandService ]
})
export class MnCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnCoreModule,
            providers: [
                MnWindowSize,
                MnTextService,
                MnBrandService,
                {
                    provide: MnBackend,
                    useFactory: MnBackendServiceFactory,
                    deps: [Http,MnBrandService]
                },

            ]
        };
    }
}

export class mn_core {
    static configure():any {
        return [
            MnCoreModule.forRoot()
        ];
    }
}