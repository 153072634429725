"use strict";

// rxjs
import 'rxjs/Rx';
import {BehaviorSubject, Subscription, Observable} from 'rxjs/Rx';

// angular
import {Input, ElementRef, Injectable, Directive, Pipe, ChangeDetectorRef} from "@angular/core";

//
import {MnBrandService} from "./MnBrandService"
import {MnUnsubscribe} from "./MnUnsubscribe";
import {MnObservableResolver} from "./MnHelpers";

let mn_text_service_instance:any = null;

@Injectable()
export class MnTextService {

    private mChangeSubject:BehaviorSubject<string> = new BehaviorSubject("");
    private mLanguages:any;
    private mTranslations:any;
    //private mTranslationsSubscription:Subscription;
    private mTemplateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;


    constructor(/*private mHttp:Http*/) {
        console.log("MnTextService created");
        if (mn_text_service_instance !== null) {
            throw new Error("Multiple instanciation error of MnTextService");
        }
        mn_text_service_instance = this;
        this.mLanguages = MnBrandService.raw().lang.support;
        this.setLanguage(MnBrandService.raw().lang.support[0].key);
    }

    public onLanguageChange(next?: (value:string) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mChangeSubject.subscribe(next,error,complete);
    }

    public setLanguage(value:string) {
        //if (!this.mLanguages[value]) return;
        for (var i = 0, l = this.mLanguages.length; i < l; i++) {
            if (this.mLanguages[i].key != value) continue;
            this.mTranslations = MnBrandService.raw().text[value];
            this.mChangeSubject.next(value);
            break;
        }
        /*if (this.mTranslationsSubscription && (!this.mTranslationsSubscription.closed)) {
            this.mTranslationsSubscription.unsubscribe();
        }
        this.mTranslationsSubscription = this.mHttp.get(`./text.${value}.json`)
            .map((res:any) => res.json())
            .subscribe((translations) => {
                this.mTranslations = translations;
                this.mChangeSubject.next(value);
            }, (error) => {
                console.log(error);
            });*/
    }

    public language():string {
        return this.mChangeSubject.getValue();
    }

    public languages():any {
        return this.mLanguages;
    }

    public text(key:string, params?:any, error?:boolean):string|boolean {
        var result = this.mTranslations[key];
        if (result) {
            if (params) {
                result = this.paramInterpolate(result,params);
            }
        } else {
            console.log("Missing translation to " + this.language() + " for:",key);
            if (error) return false;
            if (MnBrandService.raw().lang.error) {
                return MnBrandService.raw().lang.error;
            }
            return key;
        }
        return result;
    }

    private paramInterpolate(expr: string | Function, params?: any): string {
        let result: string;
        if(typeof expr === 'string') {
            if (!params) {
                result =  expr;
            } else {
                result = expr.replace(this.mTemplateMatcher, (substring: string, b: string) => {
                    let r = this.paramValue(params, b);
                    return r ? r : substring;
                });
            }
        } else if(typeof expr === 'function') {
            result =  expr(params);
        } else {
            result = expr as string;
        }
        return result;
    }

    private paramValue(target: any, key: string): any {
        if (typeof target === 'object') {
            let keys = key.split('.');
            key = '';
            do {
                key += keys.shift();
                if(target && target[key] && (typeof target[key] === 'object' || !keys.length)) {
                    target = target[key];
                    key = '';
                } else if(!keys.length) {
                    target = undefined;
                } else {
                    key += '.';
                }
            } while(keys.length);
            return target;
        } else {
            return target;
        }
    }

    public getBrowserLang(): string {
        /*if(typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return undefined;
        }

        let browserLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
        browserLang = browserLang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

        if(browserLang.indexOf('-') !== -1) {
            browserLang = browserLang.split('-')[0];
        }

        if(browserLang.indexOf('_') !== -1) {
            browserLang = browserLang.split('_')[0];
        }

        return browserLang;*/
        return "test";
    }
}


@Directive({
    selector: '[mn-text]'
})
@MnUnsubscribe()
export class MnText {

    mKeyResolver:MnObservableResolver = new MnObservableResolver((key)=>{this.onKeyChange(key)});
    mValueResolver:MnObservableResolver = new MnObservableResolver((value)=>{this.onValueChange(value)});
    mOnChange:Subscription;

    mParams:any = null;
    @Input('params') set params(value: string) {
        this.mParams = value;
        this.onChange();
    }

    mKey:string = null;
    @Input('mn-text') set key(value: string|Observable<any>) {
        this.mKeyResolver.resolve(value);
        //this.mKey = value;
        //this.onChange();
    }

    constructor(private mElRef:ElementRef, private mService:MnTextService) {
        this.mOnChange = mService.onLanguageChange((language)=> this.onChange());
        //console.log(this);
    }

    private onKeyChange(key) {
        this.mKey = key;
        this.onChange();
    }

    private onChange() {
        if (this.mKey == null) return;
        let value = this.mService.text(this.mKey,this.mParams);
        this.mValueResolver.resolve(value);
    }

    private onValueChange(text) {
        let native_element = this.mElRef.nativeElement;
        if(native_element.innerHTML !== undefined) {
            native_element.innerHTML = text;
        } else if(native_element.textContent !== undefined) {
            native_element.textContent = text;
        } else {
            native_element.data = text;
        }
    }

    /*static update(elref:ElementRef, text:string|boolean) {
        if(elref.nativeElement.innerHTML !== undefined) {
            elref.nativeElement.innerHTML = text;
        } else if(elref.nativeElement.textContent !== undefined) {
            elref.nativeElement.textContent = text;
        } else {
            elref.nativeElement.data = text;
        }
    }*/

    ngOnDestroy() {
        this.mKeyResolver.clear();
        this.mValueResolver.clear();
    }

}


/*export class MnTextValue {

    mOnChange:Subscription;
    mCached:string = "";
    mParams:any = null;

    constructor(private mService:MnTextService, private mKey:string) {
        this.mOnChange = mService.onLanguageChange((language)=> this.onChange());
    }

    private onChange() {
        if (this.mKey == null) return;
        this.mCached = <string> this.mService.text(this.mKey,this.mParams,false);
    }

    public toString() {
        console.log("Test");
        //return this.mCached;
        return "XXX";
    }

}*/

@Pipe({
    name: 'mntext',
    pure: false // required to update the value when the promise is resolved
})
export class MnTextPipe {

    mOnChange:Subscription;
    mKey:string;
    mResult:string = null;
    mParams:any;

    constructor(private mText:MnTextService, private mCDR: ChangeDetectorRef) {
        this.mOnChange = mText.onLanguageChange((language)=> this.onChange());
    }

    private onChange() {
        this.mCDR.markForCheck();
        this.mResult = null;
    }

    transform(key: string, params:any): any {
        if (this.mKey != key || this.mParams != params || this.mResult === null) {
            this.mParams = params;
            this.mKey = key;
            this.mResult = <string> this.mText.text(key,params,false);
        }
        return this.mResult
    }

    ngOnDestroy() {
        this.mOnChange.unsubscribe();
        this.mOnChange = null;
    }
}
