// rxjs
import 'rxjs/Rx';
import {Observable, Subscription} from 'rxjs/Rx';

export class MnObservableResolver {

    mSubscription:Subscription;
    constructor(private callback:(any)) {}

    public resolve(observable_or_any, ...rest:any[]) {
        if (observable_or_any instanceof Observable) {
            this.clear();
            this.mSubscription = observable_or_any.subscribe((value)=>this.callback(value,...rest));
        } else {
            this.callback(observable_or_any,...rest);
        }
    }

    public clear() {
        if (this.mSubscription) {
            this.mSubscription.unsubscribe();
        }
    }
}