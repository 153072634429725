/**
 * Created by joerg on 7/18/17.
 */

import {Renderer2, ElementRef, Injector} from "@angular/core";

export class MnRenderer {

    private Renderer:Renderer2;
    private ElRef:ElementRef;

    constructor(inj:Injector) {
        this.Renderer = inj.get(Renderer2);
        this.ElRef = inj.get(ElementRef);
    }

    public updateAttribute(attr:string,value:any) {
        if (!value) this.Renderer.removeAttribute(this.ElRef.nativeElement, attr);
        else this.Renderer.setAttribute(this.ElRef.nativeElement, attr, value);
    }
}
