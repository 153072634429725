/**
 * Created by joerg on 5/3/17.
 */

import {Subject, BehaviorSubject, Subscription} from 'rxjs/Rx';

import {Component,Optional,Input} from '@angular/core';

import {MnLayoutWidth,MnLayoutHeight} from "./MnLayout"

@Component({
    //moduleId: module.id,
    selector: 'test-component',
    templateUrl: './TestComponent.html',
    styleUrls: ['./TestComponent.css']
})
export class TestComponent {

    mWS:Subscription;
    mHS:Subscription;

    mHC:any = { value: "test" };
    mWC:any = { value: "test" };

    mInitialWidth:number = 400;
    mToggle:boolean = false;

    @Input() test_input:string = "test_input";
    test_input2:string = "test_input2";
    @Input() set XtestInput2(value) {
        this.test_input2 = value;
    }

    constructor(@Optional() private mWidth:MnLayoutWidth, @Optional() private mHeight:MnLayoutHeight) {

        console.log("TestComponent created");

        if (mWidth) {
            this.mWS = mWidth.onChange((value) => this.onWidthChange(value));
        }
        if (mHeight) {
            this.mHS = mHeight.onChange((value) => this.onHeightChange(value));
        }

    }

    onWidthChange(value) {
        console.log("onWidthChangeT",value);
        this.mWC = value;
    }

    onHeightChange(value) {
        console.log("onHeightChangeT",value);
        this.mHC = value;
    }

    changeWidth($event) {
        this.mInitialWidth+= 10;
        console.log()
        this.mWidth.request(this.mInitialWidth+'px');
    }

    onToggle() {
        this.mToggle = !this.mToggle;
    }
}

@Component({
    //moduleId: module.id,
    selector: 'debug-size',
    template: 'W: {{ getWidth() }} H: {{ getHeight() }}'
})
export class DebugSize {

    mWS:Subscription;
    mHS:Subscription;

    mHC:any = { height: "test" };
    mWC:any = { width: "test" };

    constructor(@Optional() private mWidth:MnLayoutWidth, @Optional() private mHeight:MnLayoutHeight) {

        console.log(this.mWidth);
        console.log(this.mHeight);
        if (mWidth) {
            this.mWS = mWidth.onChange((value) => this.onWidthChange(value));
        }
        if (mHeight) {
            this.mHS = mHeight.onChange((value) => this.onHeightChange(value));
        }

    }

    onWidthChange(value) {
        console.log("onWidthChange",value);
        this.mWC = value;
    }

    onHeightChange(value) {
        console.log("onHeightChange",value);
        this.mHC = value;
    }

    getHeight() {
        return JSON.stringify(this.mHC);
    }
    getWidth() {
        return JSON.stringify(this.mWC);
    }

}
