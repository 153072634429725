/**
 * Created by joerg on 6/8/17.
 */


"use strict";

// rxjs
import {Observable} from "rxjs/Rx";

// angular
import {Injectable} from '@angular/core';

//
import {MnTextService} from "./MnText";

let Brand:any = {
    Instance: {}
};

@Injectable()
export class MnBrandService {

    constructor(private mText:MnTextService){
        console.log("MnBrandService created");
        //console.log('Brand.Instance',Brand.Instance);
    };

    static init(brand:any) {
        Brand.Instance = brand;
    }

    static raw():any {
        return Brand.Instance;
    }

    public brand() {
        return Brand.Instance;
    }

    public image(img_ref) {
        if (!img_ref || !Brand.Instance.images[img_ref]) return {
          src: false,
          alt: false
        };
        return {
            src: Brand.Instance.images[img_ref],
            alt: this.text(img_ref+'.image.alt')
        }

    }

    public anchor(anc_ref) {
        if (Brand.Instance.anchors[anc_ref] instanceof Observable) {
            return Brand.Instance.anchors[anc_ref];
        }
        let result = {
            href: false,
            target: false,
            title: false
        };
        if (!anc_ref) return result;
        let anc = Brand.Instance.anchors[anc_ref];
        if (anc) {
            if (anc.href) result.href = anc.href;
            if (anc.target) result.target = anc.target;
        }
        result.title = <any> this.text(anc_ref+'.anchor.title');
        return result;
    }

    public text(text_ref) {
        return this.mText.text(text_ref,null,true);
        /*if (!text_ref || !Brand.Instance.text[text_ref]) return false;
        return Brand.Instance.text[text_ref];*/
    }

}
