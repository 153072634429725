import {
    Request,
    RequestOptionsArgs,
    Response
} from "@angular/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
//import "rxjs/add/observable/fromPromise";
//import "rxjs/add/observable/defer";
//import "rxjs/add/operator/mergeMap";
import { MnBrandService } from './MnBrandService';


/**
 * Allows for explicit authenticated HTTP requests.
 */

@Injectable()
export class MnBackend {

    mUrlPrefix:string;

    constructor(private mHttp:any, private mBrand:MnBrandService) {
        this.mUrlPrefix = this.mBrand.brand().config.backend;
        console.log("created Backend",this);
    }

    public base():string {
        return this.mUrlPrefix;
    }

    public request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
        return this.mHttp.request(this.mUrlPrefix+url,options);
    }

    public get(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.mHttp.get(this.mUrlPrefix+url,options);
    }

    public post(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.mHttp.post(this.mUrlPrefix+url,body,options);
    }

    public put(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.mHttp.put(this.mUrlPrefix+url,body,options);
    }

    public delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.mHttp.delete(this.mUrlPrefix+url,options);
    }

    public patch(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.mHttp.patch(this.mUrlPrefix+url,body,options);
    }

    public head(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.mHttp.head(this.mUrlPrefix+url,options);
    }

    public options(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.mHttp.options(this.mUrlPrefix+url,options);
    }

}


