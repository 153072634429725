/**
 * Created by joerg on 7/7/17.
 */

import {Pipe} from '@angular/core';

@Pipe({
    name: 'mnjson'
})
export class MnJsonPipe {
    transform(val) {
        return JSON.stringify(val, null, 2)
            .replace(/ /g, '&nbsp;')
            .replace(/\n/g, '<br/>');
    }
}
