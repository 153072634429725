"use strict";

// import Angular 2
import {Directive,HostListener,Renderer} from "@angular/core";

// mn/core
import {MnLayoutService} from "./MnLayout"

@Directive({
    selector: '[mn-splitter]'
})
export class MnSplitter {

    mRemove = {
        mouseup: null,
        touchend: null,
        touchcancel: null,
        mousemove: null,
        touchmove: null,
    };

    mStart = [0,0];

    constructor(private mLayoutService:MnLayoutService, public mRenderer: Renderer) {}

    @HostListener('mousedown',['$event'])
    begin(e):any {
        this.addListeners();
        e.preventDefault();
        //console.log("begin", e);
        this.mStart = [e.screenX,e.screenY];
    }

    move(e) {
        //console.log("move", e);
        var delta = [e.screenX - this.mStart[0], e.screenY - this.mStart[1]];
        //console.log(delta);
        this.mLayoutService.Layout.mParent.updateLayoutDelta(this.mLayoutService.Layout,delta,false);
    }

    end(e) {
        //console.log("end", e);
        this.removeListeners();
        var delta = [e.screenX - this.mStart[0], e.screenY - this.mStart[1]];
        //console.log(delta);
        //this.mLayoutService.Layout.mParent.updateLayout();
        this.mLayoutService.Layout.mParent.updateLayoutDelta(this.mLayoutService.Layout,delta,true);
    }

    addListener(native_element, event_type, event_handler) {
        this.removeListener(event_type);
        //this.mRemove[event_type] = this.mRenderer.listen(native_element, event_type, (event) => { event_handler(event); });
        this.mRemove[event_type] = this.mRenderer.listenGlobal('document', event_type, (event) => { event_handler(event); });
    }

    removeListener(event_type) {
        if (this.mRemove[event_type] !== null ) {
            this.mRemove[event_type]();
            this.mRemove[event_type] = null;
        }
    }

    addListeners() {
        var me = this;
        var native_element = this.mLayoutService.Layout.mParent.mElRef.nativeElement;
        this.addListener(native_element,'mouseup',function(event){ me.end(event); });
        this.addListener(native_element,'touchend',function(event){ me.end(event); });
        this.addListener(native_element,'touchcancel',function(event){ me.end(event); });
        this.addListener(native_element,'mousemove',function(event){ me.move(event); });
        this.addListener(native_element,'touchmove',function(event){ me.move(event); });
    }

    removeListeners() {
        this.removeListener('mouseup');
        this.removeListener('touchend');
        this.removeListener('touchcancel');
        this.removeListener('mousemove');
        this.removeListener('touchmove');
    }

}
