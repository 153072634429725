/**
 * Created by joerg on 7/24/17.
 */


"use strict";

// rxjs
import 'rxjs/Rx';
import {Subject, BehaviorSubject, Subscription, Observable} from 'rxjs/Rx';

// angular2
import {forwardRef, Directive, Input, ElementRef, Renderer, ContentChildren, QueryList, Injectable, Optional, SkipSelf, NgZone} from "@angular/core";

//
import {MnUnsubscribe} from "./MnUnsubscribe"

@Injectable()
export class MnSizeService {
    private eventBus$= new Subject();

    public Parent = null;

    constructor() {}

    subscribe(next) {
        return this.eventBus$.subscribe(next);
    }
    next(event){
        this.eventBus$.next(event);
    }
}

@Injectable()
export class MnLayoutService {
    Layout = null;
}

export interface MnSize {
    height?: number;
    width?: number;
}

export class MnLayoutCom {
    private mChangeSubject:BehaviorSubject<MnSize> = new BehaviorSubject({height: 0, width: 0});
    private mRequestSubject:Subject<any> = new Subject();

    constructor() {}

    public onChange(next?: (value:MnSize) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mChangeSubject.subscribe(next,error,complete);
    }

    public change(value:MnSize) {
        this.mChangeSubject.next(value);
    }

    public onRequest(next?: (value: any) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mRequestSubject.subscribe(next,error,complete);
    }

    public request(value:any) {
        this.mRequestSubject.next(value);
    }

    public current():MnSize {
        return this.mChangeSubject.getValue();
    }
}


@Injectable()
export class MnLayoutHeight extends MnLayoutCom {
    constructor() { super(); }
}

@Injectable()
export class MnLayoutWidth extends MnLayoutCom {
    constructor() { super(); }
}

@Injectable()
export class MnLayoutSize extends MnLayoutCom {
    constructor() { super(); }
}

@Injectable()
export class MnWindowSize {
    private mChangeSubject:Subject<MnSize> = new Subject();

    constuctor(zone:NgZone) {
        window.addEventListener('resize', event => {
            zone.run(() => {
                this.mChangeSubject.next({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            });
        });
    }

    public onChange(next?: (value:MnSize) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mChangeSubject.subscribe(next,error,complete);
    }

    public current():MnSize {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
}


//
//
//
export class MnLayoutItem {

    protected mName;
    private mTerms = {
        min: null,
        max: null,
        size: {
            value: 20,
            relative: false
        }
    };

    private mSplitting = null;

    private mChildren:QueryList<MnLayoutItem>;
    private mParent:MnLayoutItem;
    public mSizeSuscription;
    public mComOwnSubscription:Subscription;
    public mComParentSubscription:Subscription;

    constructor(
        public mElRef: ElementRef,
        public mRenderer: Renderer,
        public mSize: MnSizeService,
        //public mLayoutService:MnLayoutService,
        public mComOwn:MnLayoutCom,
        public mComParent:MnLayoutCom,
        public mIsCol: boolean,
        public mIsRow: boolean
    ) {

        mRenderer.setElementClass(mElRef.nativeElement,mIsRow ? 'mn-layout-row' : 'mn-layout-col',true);

        this.mSizeSuscription = this.mSize.subscribe(new_terms => { this.setRawTerms(new_terms) });
        this.mComOwnSubscription = this.mComOwn.onRequest(new_terms => { this.setRawTerms(new_terms) });
        if (this.mComParent) {
            this.mComParentSubscription = this.mComParent.onChange(value => { this.onParentChange(value) });
        }
    }

    set layoutParent(val:MnLayoutItem) { this.mParent = val; this.log("layoutParent: parent is: " + val.name); }
    get terms() { return this.mTerms; }
    set terms(value) { this.mTerms = value; }
    get name() { return this.mName; }

    initChildren(children:QueryList<MnLayoutItem>) {
        this.log("initChildren");
        this.mChildren = children;
        this.mChildren.changes.subscribe(() => this.updateChildren());
        this.updateChildren();
    }

    updateChildren() {
        this.log("updateChildren");
        var child_array = this.mChildren.toArray();
        for (var i = 0; i < child_array.length; i++) {
            child_array[i].layoutParent = this;
        }
        this.updateLayout()
    }

    setRawTerms(raw_terms) {

        if (raw_terms === null || raw_terms === "") {
            this.mTerms.size.value = 1;
            this.mTerms.size.relative = true;
        } else if (typeof raw_terms === 'string') {
            if (raw_terms.endsWith('%')) {
                this.mTerms.size.value = parseFloat(raw_terms.replace('%', ''));
                this.mTerms.size.relative = true;
            } else {
                this.mTerms.size.value = parseFloat(raw_terms.replace('px', ''));
                this.mTerms.size.relative = false;
            }
        } else {
            this.mTerms.size.value = parseFloat(raw_terms);
            this.mTerms.size.relative = false;
        }

        this.log('setRawTerms: ' + JSON.stringify(raw_terms) + ' -> ' + JSON.stringify(this.mTerms));

        //this.updateLayout();
        if (this.mParent != undefined) {
            this.mParent.updateLayout();
        }
    }

    setStyles(styles) {
        this.mRenderer.setElementStyle(this.mElRef.nativeElement,(this.mIsRow ? 'top' : 'left'), styles.pos);
        this.mRenderer.setElementStyle(this.mElRef.nativeElement,(this.mIsRow ? 'height' : 'width'), styles.ext);
    }

    updateLayout() {
        this.log('updateLayout - number of children: ' + this.mChildren.length)

        var child_array = this.mChildren.toArray();
        var child_terms = [];
        var total_relative = 0;
        var total_absolute = 0;
        for (var i = 0; i < child_array.length; i++) {
            var tt = child_array[i].terms;
            if (tt.size.relative) {
                total_relative+= tt.size.value;
            } else {
                total_absolute+= tt.size.value;
            }
            child_terms.push(tt);
        }

        this.log('updateLayout - terms of children: ' + JSON.stringify(child_terms))

        var current_relative = 0;
        var current_absolute = 0;
        for (var i = 0; i < child_terms.length; i++) {
            var t:any = child_terms[i];
            var l = {
                pos: "",
                ext: ""
            };
            if (current_relative == 0) {
                l.pos+= current_absolute + 'px';
            } else {
                //l.pos+= "calc(" + (100 * current_relative / total_relative) + '% - ' + (total_absolute - current_absolute) + "px)";
                l.pos+= 'calc(((100% - ' + total_absolute + 'px) * ' + (current_relative / total_relative) + ') + ' + current_absolute + 'px)';
            }
            if (t.size.relative) {
                l.ext+= 'calc((100% - ' + total_absolute + 'px) * ' + (t.size.value / total_relative) + ')';
                current_relative+= t.size.value;
            } else {
                l.ext+= t.size.value + 'px';
                current_absolute+= t.size.value;
            }

            child_array[i].setStyles(l);

            this.log('updateLayout - style of child: ' + i  + ': ' + JSON.stringify(l))
        }
        this.notifyChildrenTimeout();

        this.mSplitting = null;
    }

    public currentSizePixel() {
        return this.mIsRow ? this.mElRef.nativeElement.offsetHeight : this.mElRef.nativeElement.offsetWidth;
    }

    updateLayoutDelta(splitter, delta_list, fix) {
        //this.log('updateLayout - number of children: ' + this.mChildren.length)

        var delta = this.mIsRow ? delta_list[0] : delta_list[1];
        //console.log(delta);
        var before = 0;
        var after = 0;

        var child_array = this.mChildren.toArray();
        var child_terms = [];
        var total_relative = 0;
        var total_absolute = 0;
        for (var i = 0; i < child_array.length; i++) {

            if (child_array[i] === splitter && i > 0 && i+1 < child_array.length) {
                before = i-1;
                after = i+1;
            }

            var tt = child_array[i].terms;
            if (tt.size.relative) {
                total_relative+= tt.size.value;
            } else {
                total_absolute+= tt.size.value;
            }
            child_terms.push(tt);

        }

        if (before == 0 && after == 0) return;

        if (this.mSplitting === null) {
            this.mSplitting = {
                before: this.mIsRow ? child_array[before].mElRef.nativeElement.offsetWidth : child_array[before].mElRef.nativeElement.offsetHeight,
                after: this.mIsRow ? child_array[after].mElRef.nativeElement.offsetWidth : child_array[after].mElRef.nativeElement.offsetHeight,
                before_last: child_terms[before].size.value,
                after_last: child_terms[after].size.value,
            }
        }

        var before_px_n = 0;
        var before_terms_o = child_terms[before];
        var before_terms_n = {
            min: null,
            max: null,
            size: {
                value: before_terms_o.size.value,
                relative: before_terms_o.size.relative
            }
        };
        if (before_terms_n.size.relative) {
            before_terms_n.size.value+= (before_terms_n.size.value/this.mSplitting.before)*delta;
            //before_px_n = this.mSplitting.before * before_terms_n.size.value / before_terms_o.size.value;
            //before_px_n = child_array[before].currentSizePixel() + delta;
            before_px_n = this.mSplitting.before + delta;
            //console.log("dfdb",child_array[before].currentSizePixel());
        } else {
            before_terms_n.size.value+= delta;
            before_px_n = before_terms_n.size.value;
        }
        child_terms[before] = before_terms_n;

        var after_px_n = 0;
        var after_terms_o = child_terms[after];
        var after_terms_n = {
            min: null,
            max: null,
            size: {
                value: after_terms_o.size.value,
                relative: after_terms_o.size.relative
            }
        };
        if (after_terms_n.size.relative) {
            after_terms_n.size.value-= (after_terms_n.size.value/this.mSplitting.after)*delta;
            //after_px_n = this.mSplitting.after * after_terms_n.size.value / after_terms_o.size.value;
            //after_px_n = child_array[after].currentSizePixel() + delta;
            after_px_n = this.mSplitting.after + delta;
            //console.log("dfda",child_array[after].currentSizePixel());
        } else {
            after_terms_n.size.value-= delta;
            after_px_n = after_terms_n.size.value;
        }
        child_terms[after] = after_terms_n;

        //console.log("b: "+before_px_n+" a: "+after_px_n);
        if (before_px_n < 100 || after_px_n < 100) {
            before_terms_n.size.value = this.mSplitting.before_last;
            after_terms_n.size.value = this.mSplitting.after_last;
        } else {
            this.mSplitting.before_last = before_terms_n.size.value;
            this.mSplitting.after_last = after_terms_n.size.value;
        }

        if (fix) {
            //console.log("fix!");
            this.mSplitting = null;
            child_array[before].terms = before_terms_n;
            child_array[after].terms = after_terms_n;
        }

        //this.log('updateLayout - terms of children: ' + JSON.stringify(child_terms));

        var current_relative = 0;
        var current_absolute = 0;
        for (var i = 0; i < child_terms.length; i++) {
            var t = child_terms[i];
            var l = {
                pos: "",
                ext: ""
            };
            if (current_relative == 0) {
                l.pos+= current_absolute + 'px';
            } else {
                //l.pos+= "calc(" + (100 * current_relative / total_relative) + '% - ' + (total_absolute - current_absolute) + "px)";
                l.pos+= 'calc(((100% - ' + total_absolute + 'px) * ' + (current_relative / total_relative) + ') + ' + current_absolute + 'px)';
            }
            if (t.size.relative) {
                l.ext+= 'calc((100% - ' + total_absolute + 'px) * ' + (t.size.value / total_relative) + ')';
                current_relative+= t.size.value;
            } else {
                l.ext+= t.size.value + 'px';
                current_absolute+= t.size.value;
            }

            child_array[i].setStyles(l);

            //this.log('updateLayout - style of child: ' + i  + ': ' + JSON.stringify(l))
        }
        this.notifyChildrenTimeout();
    }

    notifyChildrenTimeout() {
        setTimeout(() => this.notifyChildren(), 0);
    }

    onParentChange(value) {
        this.notifyComOwn();
    }

    notifyChildren() {
        if (this.mChildren) {
            var child_array = this.mChildren.toArray();
            for (var i = 0, l = child_array.length; i < l; i++) {
                child_array[i].notifyComOwn();
                //child_array[i].notifyChildren();
            }
        }
    }

    notifyComOwn() {
        this.mComOwn.change(
            this.mIsRow ? {
                height: this.mElRef.nativeElement.offsetHeight
            } : {
                width: this.mElRef.nativeElement.offsetWidth,
            }
        );
    }

    log(message:string) {
        //console.log("Layout " + (this.mIsCol ? 'Col:' : 'Row:') + this.mName + " " + message);
    }

    onDestroy() {
        //this.mLayoutService.Layout = null;
        /*this.mSizeSuscription.unsubscribe();
        this.mComOwnSubscription.unsubscribe();
        if (this.mComParent) {
            this.mComParentSubscription.unsubscribe();
        }*/
    }
}

//
//
//
@Directive({
    selector: '[mnRow]',
    inputs: ['mnRow','mnRowName'],
    providers: [MnSizeService,MnLayoutService,MnLayoutHeight]
})
@MnUnsubscribe()
export class MnRow extends MnLayoutItem {

    @ContentChildren(forwardRef(() => MnCol)) mRowChildren;

    constructor(
        elref:ElementRef,
        renderer: Renderer,
        ssize: MnSizeService,
        public mLayoutService:MnLayoutService,
        cown:MnLayoutHeight,
        @Optional() @SkipSelf() cparent:MnLayoutHeight)
    {
        super(elref, renderer, ssize, cown, cparent, false, true);
        this.mLayoutService.Layout = this;
        this.log("instantiated");
    }

    set 'mnRow' (val) { this.setRawTerms(val); }
    set 'mnRowName'(val) { this.mName = val; }

    ngAfterContentInit() {
        this.log("ngAfterContentInit");
        this.initChildren(this.mRowChildren);
    }

    ngOnDestroy() {
        this.mLayoutService.Layout = null;
        this.onDestroy();
    }
}

//
//
//
@Directive({
    selector: '[mnCol]',
    inputs: ['mnCol','mnColName'],
    providers: [MnSizeService,MnLayoutService,MnLayoutWidth]
})
@MnUnsubscribe()
export class MnCol extends MnLayoutItem {

    @ContentChildren(MnRow) mColChildren:QueryList<MnRow>;

    constructor(
        elref:ElementRef,
        renderer: Renderer,
        ssize: MnSizeService,
        public mLayoutService:MnLayoutService,
        cown:MnLayoutWidth,
        @Optional() @SkipSelf() cparent:MnLayoutWidth)
    {
        super(elref, renderer, ssize, cown, cparent, true, false);
        this.mLayoutService.Layout = this;
        this.log("instantiated");
    }

    set 'mnCol' (val) { this.setRawTerms(val); }
    set 'mnColName' (val) { this.mName = val; }

    ngAfterContentInit() {
        this.log("ngAfterContentInit");
        this.initChildren(this.mColChildren);
    }

    ngOnDestroy() {
        this.mLayoutService.Layout = null;
        this.onDestroy();
    }
}


