/**
 * Created by joerg on 7/7/17.
 */

// angular
import {Component} from '@angular/core';

// mn
import {MnBrandService} from './MnBrandService';
import {MnTextService} from "./MnText";

function brand():any {
    return MnBrandService.raw();
}

@Component({
    selector: 'app',
    templateUrl: './MnBrandView.html'
})
export class MnBrandView {
    mLanguages:any[] = [];
    constructor(private BrandService: MnBrandService, private TextService:MnTextService) {
        this.mLanguages = BrandService.brand().lang.support;
    }
    private changeLanguage($event,lkey) {
        console.log("changing language to: ", lkey);
        this.TextService.setLanguage(lkey);
    }
}
