/**
 * Created by joerg on 7/14/17.
 */

// rxjs
import 'rxjs/add/observable/fromPromise';
import { Observable } from "rxjs/Observable";
import { Subject } from 'rxjs/Subject';

// angular
import {
    Input,
    Component,
    Compiler,
    Injector,
    NgModuleFactoryLoader,
    NgModuleFactory,
    ViewChild,
    Directive,
    DoCheck,
    Host,
    Inject,
    KeyValueChangeRecord,
    KeyValueDiffers,
    OnChanges,
    OnDestroy,
    Optional,
    SimpleChanges,
    SimpleChange
} from '@angular/core';
import {NgComponentOutlet} from '@angular/common';


export const UNINITIALIZED = Object.freeze({ __uninitialized: true });

export class CustomSimpleChange extends SimpleChange {
    isFirstChange() {
        return this.previousValue === UNINITIALIZED || super.isFirstChange();
    }
}

export type KeyValueChangeRecordAny = KeyValueChangeRecord<any, any>;

@Directive({
    selector: '[MnDynamicInputs],[MnDynamicOutputs]'
})
export class MnDynamicDirective implements OnChanges, DoCheck, OnDestroy {

    @Input() MnDynamicInputs: { [k: string]: any };
    @Input() MnDynamicOutputs: { [k: string]: Function };

    private _lastComponentInst: any = null;
    private _lastInputChanges: SimpleChanges;
    //private _inputsDiffer = this._differs.find({}).create(null as any);
    private _inputsDiffer = this._differs.find({}).create();
    private _destroyed$ = new Subject<void>();

    private get _inputs() {
        return this.MnDynamicInputs;
    }

    private get _outputs() {
        return this.MnDynamicOutputs;
    }

    private get _compOutletInst(): any {
        return this._extractCompFrom(this._componentOutlet);
    }

    private get _componentInst(): any {
        return this._compOutletInst
    }

    private get _componentInstChanged(): boolean {
        if (this._lastComponentInst !== this._componentInst) {
            this._lastComponentInst = this._componentInst;
            return true;
        } else {
            return false;
        }
    }

    constructor(
        private _differs: KeyValueDiffers,
        private _injector: Injector,
        @Host() private _componentOutlet: NgComponentOutlet,
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (this._componentInstChanged) {
            this.updateInputs(true);
            this.bindOutputs();
        }
    }

    ngDoCheck() {
        if (this._componentInstChanged) {
            this.updateInputs(true);
            this.bindOutputs();
            return;
        }

        const inputs = this._inputs;

        if (!inputs) {
            return;
        }

        const inputsChanges = this._inputsDiffer.diff(inputs);

        if (inputsChanges) {
            const isNotFirstChange = !!this._lastInputChanges;
            this._lastInputChanges = this._collectChangesFromDiffer(inputsChanges);

            if (isNotFirstChange) {
                this.updateInputs();
            }
        }
    }

    ngOnDestroy() {
        this._destroyed$.next();
    }

    updateInputs(isFirstChange = false) {
        const inputs = this._inputs;

        if (!inputs || !this._componentInst) {
            return;
        }

        Object.keys(inputs).forEach(p =>
            this._componentInst[p] = inputs[p]);

        this.notifyOnInputChanges(this._lastInputChanges, isFirstChange);
    }

    bindOutputs() {
        this._destroyed$.next();
        const outputs = this._outputs;

        if (!outputs || !this._componentInst) {
            return;
        }

        Object.keys(outputs)
            .filter(p => this._componentInst[p])
            .forEach(p => this._componentInst[p]
                .takeUntil(this._destroyed$)
                .subscribe(outputs[p]));
    }

    notifyOnInputChanges(changes: SimpleChanges = {}, forceFirstChanges: boolean) {
        // Exit early if component not interrested to receive changes
        if (!this._componentInst.ngOnChanges) {
            return;
        }

        if (forceFirstChanges) {
            changes = this._collectFirstChanges();
        }

        this._componentInst.ngOnChanges(changes);
    }

    private _collectFirstChanges(): SimpleChanges {
        const changes = {} as SimpleChanges;
        const inputs = this._inputs;

        Object.keys(inputs).forEach(prop =>
            changes[prop] = new CustomSimpleChange(UNINITIALIZED, inputs[prop], true));

        return changes;
    }

    private _collectChangesFromDiffer(differ: any): SimpleChanges {
        const changes = {} as SimpleChanges;

        differ.forEachItem((record: KeyValueChangeRecordAny) =>
            changes[record.key] = new CustomSimpleChange(record.previousValue, record.currentValue, false));

        differ.forEachAddedItem((record: KeyValueChangeRecordAny) =>
            changes[record.key].previousValue = UNINITIALIZED);

        return changes;
    }

    private _extractCompFrom(outlet: NgComponentOutlet | null): any {
        return outlet && (<any>outlet)._componentRef && (<any>outlet)._componentRef.instance;
    }

}






declare var System: any;



let lazy_libs = {};

@Component({
    selector: 'mn-lazy, [mn-lazy]',
    template: `
        <ng-container
                [ngComponentOutlet]="LazyComponent"
                [ngComponentOutletNgModuleFactory]="LazyFactory"
                [MnDynamicInputs] = "inputs"
                [MnDynamicOutputs] = "outputs"
        >
        </ng-container>
<!--
        <ng-container
                *ngComponentOutlet="LazyComponent; ngModuleFactory:LazyFactory"
                [MnDynamicInputs] = "inputs"
                [MnDynamicOutputs] = "outputs"

        >
        </ng-container>
-->
    `
})
export class MnLazy {

    ModuleLoader:any;
    LazyComponent: any;
    LazyFactory:any;
    LazyInjector:any;

    @Input() set comp(value:any) { this.mComponentGhost = value; this.load(value); }

    @Input() inputs:any = {};
    @Input() outputs:any = {};

    mComponentGhost:any;
    mInputs = {};
    mOutputs = {};
    mComponentRef:any = null;

    /*@Input() set input0(value:any) {
        if (this.mComponentRef) {
            if (this.mComponentGhost.inputs && this.mComponentGhost.inputs.input0) {
                this.mComponentRef.instance[this.mComponentGhost.inputs.input0] = value;
                this.mComponentRef.changeDetectorRef.detectChanges();
            }
        } else {
            this.mInputs[0] = value;
        }
    }*/

    @ViewChild(NgComponentOutlet) ngComponentOutlet: NgComponentOutlet;

    private clearComponent() {
        this.LazyComponent = false;
        this.LazyFactory = null;
        this.LazyInjector = null;
        this.mComponentRef = null;
        this.mComponentGhost = null;
        this.mInputs = [null,null,null]
    }

    private loadComponent(lazy) {
        System.import(lazy.lib).then( (lazy_module) => {
            if (lazy_module && lazy_module[lazy.comp]) {
                if (lazy.mod) {
                    this.LazyInjector = lazy_libs[lazy.lib][lazy.mod].injector;
                    this.LazyFactory = lazy_libs[lazy.lib][lazy.mod].factory;
                } else {
                    this.clearComponent();
                }
                this.LazyComponent = lazy_module[lazy.comp];
                console.log("Lazy cache status",lazy_libs);
            }
        }).catch( (e) => {
            console.log("Caught error loading component",lazy);
            this.clearComponent();
        });
    }

    public load(lazy) {
        if (!lazy.comp) {
            return this.clearComponent();
        }
        if (lazy.mod) {
            if (lazy_libs[lazy.lib] && lazy_libs[lazy.lib][lazy.mod]) {
                this.loadComponent(lazy);
            } else {
                this.ModuleLoader.load(lazy.lib+'#'+lazy.mod)
                    .then((lazy_module_factory) => {
                        if (!lazy_libs[lazy.lib]) {
                            lazy_libs[lazy.lib] = {};
                        }
                        if (!lazy_libs[lazy.lib][lazy.mod]) {
                            lazy_libs[lazy.lib][lazy.mod] = {
                                factory: lazy_module_factory,
                                injector: lazy_module_factory.create(this.Injector)
                            }
                        }
                        this.loadComponent(lazy);
                    }).catch( (error) => {
                        console.error(`Failed to load Module ${lazy.mod} from ${lazy.lib}`,error);
                    })
            }
        } else {
            this.loadComponent(lazy);
        }
        /*var me = this;
        setTimeout(() => {
            console.log(me.ngComponentOutlet);
            me.mComponentRef = me.ngComponentOutlet['_componentRef'];
            for (var i = 0, l = me.mInputs.length; i < l; i++) {
                if (me.mInputs[i] == null) continue;
                if (me.mComponentGhost.inputs && me.mComponentGhost.inputs['input'+i]) {
                    me.mComponentRef.instance[me.mComponentGhost.inputs['input'+i]] = me.mInputs[i];
                }
            }
            me.mComponentRef.changeDetectorRef.detectChanges();
        },1000);*/
    }

    constructor(private Compiler: Compiler, private Injector: Injector) {
        this.ModuleLoader = this.Injector.get(NgModuleFactoryLoader);
    }

    static loadLibModule(injector:Injector, library:string, module:string) {
        if (lazy_libs[library] && lazy_libs[library][module]) {
            return Observable.of(true);
            //this.loadComponent(lazy);
        } else {
            var module_loader = injector.get(NgModuleFactoryLoader);
            /*module_loader.load(library+'#'+module)
                .then((lazy_module_factory) => {
                    if (!lazy_libs[library]) {
                        lazy_libs[library] = {};
                    }
                    if (!lazy_libs[library][module]) {
                        lazy_libs[library][module] = {
                            factory: lazy_module_factory,
                            injector: lazy_module_factory.create(injector)
                        }
                    }
                }).catch( (error) => {
                    console.error(`Failed to load Module ${module} from ${library}`,error);
                });*/
            return Observable.fromPromise(module_loader.load(library+'#'+module)).map( (lazy_module_factory:NgModuleFactory<any>) => {
                if (!lazy_libs[library]) {
                    lazy_libs[library] = {};
                }
                if (!lazy_libs[library][module]) {
                    lazy_libs[library][module] = {
                        factory: lazy_module_factory,
                        injector: lazy_module_factory.create(injector)
                    }
                }
                return true;
            });
        }

    }


    static loadLibModulePromise(injector:Injector, library:string, module:string) {
        if (lazy_libs[library] && lazy_libs[library][module]) {
            return Promise.resolve(lazy_libs[library][module]);
            //this.loadComponent(lazy);
        } else {
            var module_loader = injector.get(NgModuleFactoryLoader);
            return module_loader.load(library + '#' + module)
                .then((lazy_module_factory) => {
                    if (!lazy_libs[library]) {
                        lazy_libs[library] = {};
                    }
                    if (!lazy_libs[library][module]) {
                        lazy_libs[library][module] = {
                            factory: lazy_module_factory,
                            injector: lazy_module_factory.create(injector)
                        }
                    }
                    var instance = lazy_libs[library][module].injector.instance;
                    if (instance.initMnModule && (typeof instance.initMnModule === "function")) {
                        return instance.initMnModule();
                    } else {
                        return lazy_libs[library][module];
                    }
                }).catch((error) => {
                    console.error(`Failed to load Module ${module} from ${library}`, error);
                });
        }
    }
}


