/**
 * Created by joerg on 7/18/17.
 */

import {Directive, Input, Injector} from "@angular/core";

import {MnBrandService} from "./MnBrandService";
import {MnRenderer} from "./MnRenderer";

@Directive({
    selector:'img[mn-image]'
})
export class MnImage extends MnRenderer {

    ImgRef:string = "";
    @Input() set "mn-image" (value:any) { this.ImgRef = value; this.update(); }

    constructor(private Brand: MnBrandService, private Inj:Injector) { super(Inj); }

    ngOnInit() {
        this.update();
    }

    update() {
        var img = this.Brand.image(this.ImgRef);
        this.updateAttribute('src',img.src);
        this.updateAttribute('alt',img.alt);
    }
}
